import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { BaseLayout } from '../components/Layout';
import { ChainTypeValue } from '../lib/constants/auth';
import { Role } from '../lib/constants/user';
import { useEffectAsync } from '../lib/useEffectAsync';
import { userAtom } from '../state/user';

interface IIndexPageProps {
  chain: ChainTypeValue;
}

export default function Index({ chain }: IIndexPageProps) {
  const router = useRouter();
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (!chain) {
      router.reload();
    }
  }, [chain, router]);

  useEffectAsync(async () => {
    if (!user) return;
    if (user?.role === Role.ADMIN || user?.role === Role.SUPER_ADMIN) {
      router.push('/admin');
    } else {
      router.push('/claim');
    }
  }, [user]);

  return (
    <BaseLayout chain={chain}>
      <h1 className="text-gray-300">Checking wallet permissions...</h1>
    </BaseLayout>
  );
}
